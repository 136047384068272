import React, { useState } from "react"
import styled, { css } from "styled-components"
import { bp, colors, fonts } from "./Styles"
import ArrowSVG from "../images/icons/UI-Icons_Pfeil_weiß.svg"

interface SimpleTheme {
  primary: string
  secondary: string
  font: string
  placeholderFont: string
  decoration: string
}

const themes: { [key: string]: SimpleTheme } = {
  primary: {
    font: "#ffffff",
    placeholderFont: "#ffffff",
    primary: colors.backgroundLighter,
    secondary: colors.background,
    decoration: colors.primary,
  },
  secondary: {
    font: "#ffffff",
    placeholderFont: colors.background,
    primary: colors.primaryDarker,
    secondary: colors.primary,
    decoration: "#ffffff00",
  },
}

type Theme = keyof typeof themes

interface SubmitInputProps
  extends Omit<React.HTMLProps<HTMLDivElement>, "onClick"> {
  placeholder?: string
  theme?: Theme
  onClick?: () => void
  ariaLabel: string
  disabled?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const SubmitInput: React.FC<SubmitInputProps> = ({
  className,
  style,
  placeholder,
  onClick = () => {},
  theme = "primary",
  ariaLabel,
  disabled,
  onChange = () => {},
}) => {
  return (
    <InputContainer
      colorTheme={themes[theme]}
      {...{ className, style, disabled }}
    >
      <input
        type="email"
        onChange={e => onChange(e)}
        {...{ placeholder, disabled }}
      />

      <button onClick={_ => onClick()} aria-label={ariaLabel}>
        <ArrowSVG />
      </button>
    </InputContainer>
  )
}

const InputContainer = styled.div<{
  colorTheme: SimpleTheme
  disabled?: boolean
}>`
  position: relative;
  max-width: 100%;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.8;
      cursor: default;
    `}

  input {
    height: 100%;
    background: ${colors.primary};
    outline: none;
    font-size: 0.9em;
    color: ${colors.black};
    padding: 1.4em 5.5em 1.3em 2em;
    width: 100%;
    font-family: ${fonts.bold};
    border-radius: unset;
    ${bp("mobile")`
    font-size: 0.9em;
    padding: 1.5em 5.5em 1.6em 2em;
    `}
    ::placeholder {
      color: ${colors.white};
      opacity: 1;
      font-family: ${fonts.bold};
    }
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 4.6rem;
    background: ${colors.blue};
    cursor: pointer;

    > svg {
      width: 1.6rem;
      vertical-align: middle;
    }
  }
`

export default SubmitInput
