import React, { useContext, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import BurgerMenuIcon from "../components/BurgerMenuIcon"
import { colors, bp, layout, fonts } from "./Styles"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import FadeLink from "./FadeLink"
import { MenuContext } from "./Page"
import { useI18next } from "gatsby-plugin-react-i18next"
import { KontaktMenuInner } from "./KontaktMenu"

const Container = styled.div`
  height: 100%;
  position: fixed;
  pointer-events: none;
  font-size: 1rem;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  overflow: hidden;
`

const Header = styled.header`
  background-color: #fff;
  height: 7.8rem;
`
const HeaderContainer = styled.nav`
  width: ${layout.width};
  max-width: ${layout.maxWidth};
  margin: 0 auto;
  display: flex;
  flex-direction: row;
`

const MenuLabel = styled.h3``

const LinkeHeader = styled(FadeLink)<{ current?: boolean }>`
  flex: auto;
  text-transform: uppercase;
  transition: all ease-in-out 0.15s;
  &[aria-current="page"] h3{
    color: ${colors.blue};
  }
  > ${MenuLabel} {
    margin: 0;
    padding: 0;
    display: inline-block;
    font-size: 1.1em;
    position: relative;
    padding: 3em 0;
    color: #000000;
    font-weight: bold;
    :before {
      content: "";
      background-color: ${props => (props.current ? "#00ffaf" : "transparent")};
      height: ${props => (props.current ? "1.7em" : "0")};
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: all ease-in-out 0.2s;
    }
    :hover:before {
      background-color: ${colors.primary};
      height: 0.7em;
    }
  }
  // Loay will change this
  &[aria-current="page"] h3:before {
    background-color: ${colors.primary};
    height: 1.7em !important;
  }
  }
`
const SideMenu = styled.div<{ isOpen: boolean }>`
  background-color: #fff;
  transition: width 0.4s;
  pointer-events: none;
  align-self: flex-end;
  height: 100%;
  pointer-events: auto;
  max-width: 65%;
  overflow-y: auto;
  box-shadow: 0px 0px 20px #00000030;
  width: ${props => (props.isOpen ? "60%" : "0px")};
`

export const MobileMenuContainer = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: 100%;
  pointer-events: none;
  font-size: 1.15em;
  padding-top: 7.9em;
  position: relative;

  ${props => {
    if (props.isOpen)
      return css`
        opacity: 1;
        transition: opacity 0.2s 0.3s;
      `
    return css`
      opacity: 0;
      transition: opacity 0.1s;
    `
  }}
`

export const MobileHeaderLinkContainer = styled.div`
  color: ${colors.black};
  font-family: ${fonts.medium};
  width: 100%;
  padding: 1.6em;
  font-size: 1.5em;
  pointer-events: auto;
  text-align: right;
  text-transform: uppercase;
  padding-bottom: 0;
  > a {
    position: relative;
    &[aria-current="page"] {
      color: ${colors.blue};
    }
    ::after {
      content: "";
      position: absolute;
      top: -10px;
      bottom: -10px;
      left: -10px;
      right: -10px;
    }
  }
`

export const MobileHeaderLink: React.FC<{ link: string }> = ({
  children,
  link,
}) => (
  <MobileHeaderLinkContainer>
    <FadeLink href={link}>{children}</FadeLink>
  </MobileHeaderLinkContainer>
)

interface MenuProps {
  isOpen: boolean
  setOpenState: (val: boolean | ((prev: boolean) => boolean)) => void
  current?: boolean
}

const Menu = () => {
  const [isOpen, setOpenState] = useState<boolean>(false)

  const menuContext = useContext(MenuContext)

  useEffect(() => {
    menuContext.registerMenuClose(() => {
      if (isOpen) {
        setOpenState(false)
      }
    })
  }, [menuContext])

  const isMobile = useBreakpoint().mobile

  if (isMobile) {
    return (
      <MobileMenu isOpen={isOpen} setOpenState={val => setOpenState(val)} />
    )
  }
  return <DesktopMenu isOpen={isOpen} setOpenState={val => setOpenState(val)} />
}

const MobileMenu: React.FC<MenuProps> = ({ isOpen, setOpenState }) => {
  const { t } = useI18next()

  return (
    <MobileContainer {...{ isOpen }}>
      <BurgerMenuIcon
        onClick={() => setOpenState(prev => !prev)}
        className={`${isOpen ? "animate" : ""}`}
      />

      <SideMenu {...{ isOpen }}>
        <MobileMenuContainer {...{ isOpen }}>
          <MobileHeaderLink link="/">{t("G51 Solutions")}</MobileHeaderLink>
          <MobileHeaderLink link="/ansatz/">{t("Ansatz")}</MobileHeaderLink>
          <MobileHeaderLink link="/expertise/">
            {t("Expertise")}
          </MobileHeaderLink>
          <MobileHeaderLink link="/cases/">{t("Cases")}</MobileHeaderLink>
          {/* Todo */}
          <KontaktMenuInner />
        </MobileMenuContainer>
      </SideMenu>
    </MobileContainer>
  )
}

const DesktopMenu: React.FC<MenuProps> = ({}) => {
  const { t } = useI18next()
  return (
    <Header>
      <HeaderContainer>
        <LinkeHeader href="/" aria-label="startpage">
          <MenuLabel>{t("G51 Solutions")}</MenuLabel>
        </LinkeHeader>

        <LinkeHeader href="/ansatz/" aria-label="ansatz">
          <MenuLabel>{t("Ansatz")}</MenuLabel>
        </LinkeHeader>

        <LinkeHeader href="/expertise/" aria-label="expertise">
          <MenuLabel>{t("Expertise")}</MenuLabel>
        </LinkeHeader>

        <LinkeHeader href="/cases/" aria-label="cases">
          <MenuLabel>{t("Cases")}</MenuLabel>
        </LinkeHeader>

        {/* Todo */}
        <KontaktMenuInner />
      </HeaderContainer>
    </Header>
  )
}

const MobileContainer = styled(Container)<{ isOpen?: boolean }>`
  width: 100%;
  display: none;
  flex-direction: column;
  background-color: transparent;

  ${bp("mobile")`
    display: flex;
  `}
`

export default Menu
