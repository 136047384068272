import React from "react"
import styled from "styled-components"
import Button from "../Button"
import { ModalHeader } from "../Modal"
import ArrowWhiteSVG from "../../images/icons/UI-Icons_Pfeil_weiß.svg"
import ArrowGreySVG from "../../images/icons/UI-Icons_Pfeil_Grau.svg"
import { bp, fonts } from "../Styles"
import FadeLink from "../FadeLink"
import { useTranslation } from "gatsby-plugin-react-i18next"

interface CookieNoticeProps {
  onAcceptAll?: () => void
  onGotoSettings?: () => void
  onClose?: () => void
}

const CookieNotice: React.FC<CookieNoticeProps> = ({
  onAcceptAll = () => {},
  onGotoSettings = () => {},
  onClose = () => {},
}) => {
  const { t } = useTranslation()

  return (
    <CookieNoticeContainer>
      <ModalHeader
        title={t("Wir verwenden Cookies")}
        onClose={() => onClose()}
      />

      <p className="cookie-notice">
        {t(
          "Diese Website benutzt Cookies, die für den technischen Betrieb der Website erforderlich sind und stets gesetzt werden. Andere Cookies, die der Analyse des Nutzungsverhaltens dienen, werden nur mit Ihrer Zustimmung gesetzt. In den"
        )}{" "}
        <strong>{t("Einstellungen")}</strong>{" "}
        {t(
          "haben Sie die Möglichkeit die Cookies individuell zu akzeptieren oder abzulehnen und mehr über diese zu erfahren. Für die Zukunft können Sie Ihre Einwilligung jederzeit widerrufen."
        )}
      </p>

      <div className="cookie-links">
        <FadeLink href="/privacy" onClick={() => onClose()}>
          {t("Datenschutz")}
        </FadeLink>
        <FadeLink href="/legalnotice" onClick={() => onClose()}>
          {t("Impressum")}
        </FadeLink>
      </div>

      <div className="cookie-buttons">
        <Button variant="primary" onClick={() => onAcceptAll()}>
          {t("Alle Cookies akzeptieren")} <ArrowWhiteSVG />
        </Button>

        <Button variant="white" onClick={() => onGotoSettings()}>
          {t("Zu den Einstellungen")} <ArrowGreySVG />
        </Button>
      </div>
    </CookieNoticeContainer>
  )
}

const CookieNoticeContainer = styled.div`
  background: #fff;
  font-size: 1.2rem;
  position: relative;
  width: 44em;
  max-width: 100%;
  padding: 2em;

  .cookie-notice {
    font-size: 0.75em;
    line-height: 1.95;
    padding-right: 4em;
  }
  .close-button {
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
  }
  .cookie-links {
    font-size: 0.75em;
    font-family: ${fonts.bold};
    margin: 2.75em 0 3.55em;

    a {
      padding: 0.5em 2.5em 0.5em 0;
    }
  }

  .cookie-buttons {
    display: flex;

    > button {
      font-size: 0.78em;
      margin-right: 20px;
    }
  }

  ${bp("mobile")`
    font-size: 1.4rem;

    .cookie-notice {
      font-size: 0.85em;
      line-height: 1.75;
    }

    .cookie-buttons {
      flex-direction: column-reverse;

      > button {
        font-size: 1.3rem;
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  `}
`

export default CookieNotice
