import React, { FormEvent, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import Modal, { ModalHeader } from "../Modal"
import { bp, colors, fonts } from "../Styles"
import Checkbox from "../Checkbox"
import ContactInput from "../ContactInput"
import SubmitInput from "../SubmitInput"
import FadeLink from "../FadeLink"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { event } from "../../util/ssr"
import TextAlert from "./TextAlert"

interface ContactRequest {
  firstName: string
  lastName: string
  company: string
  email: string
  accept: boolean
  subscribe: boolean
}

interface ContactFormProps {
  hideClosingTag?: boolean
  onClose?: () => void
  onSent?: () => void
  onSubmit?: (req: ContactRequest) => void
  spacing?: Spacing
}

type Spacing = "compact" | "loose"

const ContactForm: React.FC<ContactFormProps> = ({
  onClose = () => {},
  onSent = () => {},
  hideClosingTag,
  spacing = "compact",
}) => {
  useEffect(() => {
    event("contact_form_opened", {
      page: window.location.pathname,
    })
  }, [])

  const { t } = useTranslation()
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [company, setCompany] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")

  const [accept, setAccept] = useState(false)
  const [subscribe, setSubscribe] = useState(false)

  const [hasCheckError, setHasCheckError] = useState(false)
  const [hasEmailError, setHasEmailError] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const val = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const validEmail = val.test(email)

    if (!validEmail || !accept) {
      event("contact_form_send_fail", {
        page: window.location.pathname,
        valid_email: validEmail ? "yes" : "no",
        privacy_notice_accepted: accept ? "yes" : "no",
      })

      setHasEmailError(!validEmail)
      setHasCheckError(!accept)
      return
    }

    const request = {
      email,
      message,
      signupNewsletter: subscribe,
      fields: {
        Vorname: firstName,
        Nachname: lastName,
        Firma: company,
      },
    }

    setIsLoading(true)
    event("contact_form_send", {
      page: window.location.pathname,
    })

    try {
      await fetch("https://microsite-api.g51.dev/contact", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(request),
      })

      onSent()
      setIsLoading(false)
      resetForm()
    } catch (e) {
      console.dir(e)
      onSent()
      setIsLoading(false)
      resetForm()
    }
  }

  const resetForm = () => {
    setFirstName("")
    setLastName("")
    setEmail("")
    setCompany("")
    setMessage("")
  }

  return (
    <ContactFormContainer {...{ spacing }}>
      <ModalHeader
        className="header"
        title={t("Gemeinsam Lösungen finden.")}
        {...{ hideClosingTag, onClose }}
      />

      <p>
        {t(
          "Die Zukunft Ihres Unternehmes liegt in der digitalen Transformation."
        )}
      </p>

      <form onSubmit={e => onSubmit(e)} noValidate>
        <MessageInput
          placeholder={t("Nachricht")}
          {...{ spacing }}
          disabled={isLoading}
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
        <ContactFormInputs {...{ spacing }}>
          <ContactInput
            placeholder={t("Vorname")}
            onChange={s => setFirstName(s.target.value)}
            value={firstName}
            disabled={isLoading}
          />

          <ContactInput
            placeholder={t("Nachname")}
            onChange={s => setLastName(s.target.value)}
            value={lastName}
            disabled={isLoading}
          />

          <ContactInput
            placeholder={t("Firma")}
            onChange={s => setCompany(s.target.value)}
            value={company}
            disabled={isLoading}
          />

          <SubmitInput
            className="email"
            disabled={isLoading}
            onChange={s => {
              setEmail(s.target.value)
              setHasEmailError(false)
            }}
            placeholder={t("E-Mail")}
            ariaLabel="send"
          />
        </ContactFormInputs>

        {(hasEmailError || hasCheckError) && (
          <div className="errors">
            {hasCheckError && (
              <p className="error error-check">
                {t("Bitte akzeptieren Sie die")}{" "}
                <strong>{t("Datenschutzerklärung")}</strong>.
              </p>
            )}

            {hasEmailError && (
              <p className="error error-mail">
                {t("Bitte geben Sie eine gültige")}{" "}
                <strong>{t("E-Mail-Adresse")}</strong> an.
              </p>
            )}
          </div>
        )}

        <Checkbox
          className="check"
          variant="primary"
          onChange={c => {
            setAccept(c)
            setHasCheckError(false)
          }}
          disabled={isLoading}
          checked={accept}
        >
          {t("Ich akzeptiere die")}{" "}
          <FadeLink href="/privacy" onClick={() => onClose()}>
            {t("Datenschutzerklärung")}
          </FadeLink>
        </Checkbox>

        <Checkbox
          className="check"
          variant="primary"
          onChange={c => setSubscribe(c)}
          disabled={isLoading}
          checked={subscribe}
        >
          {t("Bitte informieren Sie mich über neue Entwicklungen")}
        </Checkbox>
      </form>
    </ContactFormContainer>
  )
}

const ContactFormContainer = styled.div<{ spacing: Spacing }>`
  padding: 2em 5em;
  background: ${colors.backgroundDarker};
  color: ${colors.slideSecondry};
  ${({ spacing }) =>
    spacing === "loose" &&
    css`
      padding: 2em 0 3em 0;
    `}

  font-size: 1rem;
  width: 72em;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .header {
    align-items: flex-start;
    margin-bottom: 2.35em;

    h3 {
      font-size: 3em;
      padding-top: 0.65em;
      color: ${colors.primary};
    }
  }

  *:disabled {
    opacity: 0.6;
    cursor: default;
  }

  textarea:disabled {
    background-color: #fff;
  }

  p {
    width: 36em;
    max-width: 100%;
    font-size: 2.5em;
    margin-bottom: 1em;
    color: ${colors.slideSecondry};
  }

  p.error {
    margin-bottom: 0.2em;
    color: #de1616;
    font-size: 0.9em;
    strong {
      color: #de1616;
    }
  }

  div.errors {
    margin-bottom: 2em;
  }

  .check {
    margin-bottom: 1.25em;
    font-size: 0.8em;
  }

  form {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  ${({ spacing }) =>
    spacing === "loose" &&
    css`
      width: 90em;

      > div {
        margin: 0 2em 2em 0;
      }
    `}

  ${bp("mobile")`
    padding: 3em;

    p {
      font-size: 1.25rem;
    }

    .check {
      margin-bottom: 1.25em;
      font-size: 1em;
    }

    .header h3 {
      font-size: 2.5em;
      line-height: 1.5;
      padding-top: 0;
    }

    .email {
      font-size: 0.9em;
    }
    
  `}
`

const ContactFormInputs = styled.div<{ spacing: Spacing }>`
  display: flex;
  width: 100%;
  max-width: 100%;

  > div {
    width: calc(26% - 1em);
    margin: 0 1em 1em 0;
    &:last-child {
      margin-right: 0;
    }
  }

  ${({ spacing }) =>
    spacing === "loose" &&
    css`
      width: 90em;

      > div {
        margin: 0 2em 2em 0;
      }
    `}

  ${bp("mobile")`
    flex-direction: column;
    font-size: 1.2rem;

    > div {
      width: 100%;
      margin: 0 0 1em 0;
    }
  `}
`

const MessageInput = styled.textarea<{ spacing: Spacing }>`
  font-size: 0.9em;
  font-family: ${fonts.bold};
  padding: 1.3em 1.1em 1.3em 3.2em;
  border: none;
  box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.06);
  resize: none;
  width: 100%;
  height: 10em;
  margin-bottom: 1.5em;
  transition: border 0.15s linear;
  border: 2px solid transparent;
  outline: none;
  max-width: 100%;
  border-radius: unset;

  ${({ spacing }) =>
    spacing === "loose" &&
    css`
      width: 70em;
      margin-bottom: 3em;

      ${bp("ldpi")`
    width: 60em;
  `}
    `}

  :focus {
    border: 2px solid ${colors.primary};
  }

  ::placeholder {
    opacity: 1;
    color: ${colors.slideBlue};
  }

  ${bp("mobile")`
    width: 100%;
  `}
`

const SendButton = styled.button`
  font-size: 0.9em;
  font-family: ${fonts.bold};
  display: flex;
  justify-items: space-between;
  align-items: stretch;
  margin-top: 0.85em;
  background-color: ${colors.primary};
  border-radius: 10px;
  padding: 0;
  overflow: hidden;
  cursor: pointer;

  span {
    padding: 1.4em 3.2em 1.1em 3.2em;
  }

  div {
    padding: 0 2em;
    background-color: ${colors.primaryDarker};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 1.5em;
  }

  ${bp("mobile")`
    font-size: 1.2rem;
  `}
`

export const ThanksModal = ({ isShown = false, onClose = () => {} }) => (
  <Modal {...{ onClose, isShown }} showBackdrop>
    <TextAlert {...{ onClose }} title="Vielen Dank!">
      <p style={{ marginBottom: "0.4em" }}>
        Ihre Kontaktanfrage wurde versendet.
      </p>
      <p>Wir melden uns so bald wie möglich bei Ihnen zurück.</p>
    </TextAlert>
  </Modal>
)

export default ContactForm
