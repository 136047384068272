import React, { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import { bp, colors, fonts } from "./Styles"
import Modal from "./Modal"
import ContactForm, { ThanksModal } from "./modals/ContactForm"
import useIntersectionObserver from "../util/use-intersection-observer"
import { useTranslation } from "gatsby-plugin-react-i18next"

const KontaktMenu: React.FC<{
  href?: string
  shouldAppear?: boolean
  onClick?: (e: React.MouseEvent<any, MouseEvent>) => void
  className?: string
}> = ({ onClick, href, className, children }) => {
  const { t } = useTranslation()
  return (
    <>
      <MenuLink onClick={e => onClick?.(e)} className={className}>
        <h3>{t("Kontakt")}</h3>
      </MenuLink>
    </>
  )
}

export const KontaktMenuInner: React.FC<{ hideExtras?: boolean }> = ({
  hideExtras = false,
}) => {
  const [showModal, setshowModal] = useState(false)
  const fromElement = useRef<HTMLElement | null>(null)
  const [showCTA, setshowCTA] = useState(false)
  const containerMRef = useRef<HTMLDivElement>(null)
  const entry = useIntersectionObserver(containerMRef, {
    freezeOnceVisible: true,
    threshold: 0.9,
  })

  useEffect(() => {
    if (!entry?.isIntersecting) return
    setshowCTA(true)
  }, [entry])

  const [wasSent, setWasSent] = useState(false)

  return (
    <KontaktMenuInnerContainer {...{ hideExtras, showCTA }} ref={containerMRef}>
      <KontaktMenu
        onClick={e => {
          fromElement.current = e.target as HTMLElement
          setshowModal(true)
        }}
        className={`${showModal ? "active" : ""}`}
      ></KontaktMenu>

      <Modal
        onClose={() => setshowModal(false)}
        isShown={showModal}
        showBackdrop
        fromElement={fromElement}
      >
        <ContactForm
          onClose={() => setshowModal(false)}
          onSent={() => {
            setshowModal(false)
            setWasSent(true)
          }}
        />
      </Modal>

      <ThanksModal isShown={wasSent} onClose={() => setWasSent(false)} />
    </KontaktMenuInnerContainer>
  )
}

const KontaktMenuInnerContainer = styled.div`
  //
`

const MenuLink = styled.div`
cursor: pointer;
flex: auto;
text-transform: uppercase;
transition: all ease-in-out 0.15s;
&[aria-current="page"] h3{
  color: ${colors.blue};
}
> h3 {
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 1.1em;
  position: relative;
  padding: 3em 0;
  color: #000000;
  font-weight: bold;
  :before {
    content: "";
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all ease-in-out 0.2s;
  }
  :hover:before {
    background-color: ${colors.primary};
    height: 0.7em;
  }
}
// Loay will change this
&[aria-current="page"] h3:before {
  background-color: ${colors.primary};
  height: 1.7em !important;
}
}
${bp("mobile")`
h3 {
    color: ${colors.black};
    font-family: ${fonts.medium};
    width: 100%;
    padding: 1.6em;
    font-size: 1.5em;
    pointer-events: auto;
    text-align: right;
    text-transform: uppercase;
    padding-bottom: 0;
    :before {
        content: none;
    }
    font-weight: normal;
}
    `}
    `
export default KontaktMenu
