import React from "react"
import styled, { css } from "styled-components"
import { colors } from "./Styles"

interface BurgerProps {
  className?: string
  onClick?: (e: React.MouseEvent<any, MouseEvent>) => void
}

const BurgerMenuIcon: React.FC<BurgerProps> = BurgerProps => {
  return (
    <MobileHeaderRow>
      <BurgerMenuOuter onClick={BurgerProps.onClick}>
        <BurgerMenuInner className={BurgerProps.className}></BurgerMenuInner>
      </BurgerMenuOuter>
    </MobileHeaderRow>
  )
}

const MobileHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: auto;
  position: absolute;
  top: 2rem;
  right: 0;

  > :first-child {
    flex: 1;
  }
`

const BurgerMenuOuter = styled.div`
  position: relative;
  width: 5rem;
  height: 4rem;
`
const BurgerMenuInner = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #00ffaf;
  width: 2.5rem;
  height: 0.2rem;
  ::before {
    content: "";
    width: 2.5rem;
    height: 0.2rem;
    position: absolute;
    left: 0;
    bottom: -8px;
    background: ${colors.primary};
    transition: all 0.3s;
  }

  ::after {
    content: "";
    width: 2.5rem;
    height: 0.2rem;
    position: absolute;
    left: 0;
    top: -8px;
    background: ${colors.primary};
    transition: all 0.3s;
  }
  &.animate {
    background: transparent;
  }
  &.animate:before {
    bottom: 0;
    transform: rotate(-45deg);
  }
  &.animate:after {
    top: 0;
    transform: rotate(45deg);
  }
`

export default BurgerMenuIcon
