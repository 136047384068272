import React, { FormEvent, InputHTMLAttributes, useState } from "react"
import styled, { css } from "styled-components"
import { ModalHeader } from "./Modal"
import { bp, colors, fonts } from "./Styles"
import Checkbox from "./Checkbox"
import SendArrowSVG from "../images/icons/UI-Icons_Pfeil_Grau.svg"

const ContactInputWrapper = styled.div<{
  withSubmit?: boolean
  disabled?: boolean
}>`
  background-color: #00000000;
  position: relative;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      opacity: 0.8;
    `}

  div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  input:focus ~ div {
    ::before {
      left: 6px;
      right: 6px;
      bottom: -2px;
      top: -6px;
      border: 1px solid ${colors.background};
    }

    ::after {
      bottom: -8px;
    }
  }

  input {
    font-size: 0.9em;
    font-family: ${fonts.bold};
    color: ${colors.black};
    padding: 1.4em 2em 1.3em 2em;
    width: 100%;
    background: ${colors.primary};
    border-radius: unset;

    ::placeholder {
      color: ${colors.white};
      opacity: 1;
    }
  }

  ${bp("mobile")`
    input:focus ~ div {
      ::before {
        left: 4px;
        right: 4px;
        bottom: -1px;
        top: -5px;
        border: 1px solid ${colors.background};
      }

      ::after {
        bottom: -5px;
      }
    }
  `}

  ${props =>
    props.withSubmit &&
    css`
      padding-right: 5em;

      .submit-button {
        position: absolute;
        right: 0;
        z-index: 1;
        margin: 0;
        padding: 0;
        background-color: ${colors.primaryDarker};
        width: 5em;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        cursor: pointer;

        svg {
          width: 1.8em;
        }
      }
    `}
`

interface ContactInputProps {
  withSubmit?: boolean
  ariaLabel?: string
}

const ContactInput: React.FC<
  InputHTMLAttributes<HTMLInputElement> & ContactInputProps
> = ({ withSubmit, ariaLabel, disabled, ...props }) => (
  <ContactInputWrapper {...{ withSubmit, disabled }}>
    <input {...{ ...props, disabled }} />
    <div />

    {withSubmit && (
      <button type="submit" className="submit-button" aria-label={ariaLabel}>
        <SendArrowSVG />
      </button>
    )}
  </ContactInputWrapper>
)

export default ContactInput
