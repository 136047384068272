import React from "react"
import styled, { css } from "styled-components"
import { colors } from "./Styles"
import { lighten } from "polished"

type Variant = "primary" | "secondary"

interface CheckboxProps
  extends Omit<React.HTMLProps<HTMLLabelElement>, "onChange"> {
  onChange?: (c: boolean) => void
  defaultChecked?: boolean
  variant?: Variant
}

const Checkbox: React.FC<CheckboxProps> = ({
  style,
  className,
  variant,
  children,
  onChange,
  defaultChecked,
  checked,
  disabled,
}) => {
  return (
    <CheckboxContainer {...{ style, className, variant, disabled }}>
      {children}

      <input
        type="checkbox"
        onChange={e => onChange?.(e.target.checked)}
        {...{ checked, defaultChecked, disabled }}
      />

      <span className="checkmark" />
    </CheckboxContainer>
  )
}

// NOTE(hilmar): light, mid, dark
const colorForVariant = {
  primary: [
    lighten(0.4, colors.primary),
    lighten(0.1, colors.primary),
    colors.primary,
  ],
  secondary: [
    lighten(0.3, colors.secondary),
    colors.secondaryLighter,
    colors.secondary,
  ],
}

const getColor = (index: number) => (props: { variant?: Variant }) =>
  colorForVariant[props.variant || "primary"][index]

const light = getColor(0)
const mid = getColor(1)
const dark = getColor(2)

const CheckboxContainer = styled.label<{
  variant?: Variant
  disabled?: boolean
}>`
  position: relative;
  padding-left: 24px;
  cursor: pointer;
  height: 17px;
  line-height: 17px;
  color: ${colors.slideSecondry};
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}

  a {
    color: ${dark};
    text-decoration: underline;

    :hover {
      color: ${mid};
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: -2px;
    left: 0;
    height: 17px;
    width: 17px;
    border: 2px solid ${dark};
    transition: all 0.1s linear;
    transition-property: background-color border-color;
  }

  input:checked ~ .checkmark {
    background: ${mid};
  }

  a:hover ~ .checkmark {
    background: #fff !important;
  }

  :hover input ~ .checkmark {
    background: ${light};
  }

  :hover input:checked ~ .checkmark {
    background: ${dark};
    border-color: ${dark};
  }
`

export default Checkbox
