// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-ansatz-tsx": () => import("./../../../src/pages/ansatz.tsx" /* webpackChunkName: "component---src-pages-ansatz-tsx" */),
  "component---src-pages-cases-tsx": () => import("./../../../src/pages/cases.tsx" /* webpackChunkName: "component---src-pages-cases-tsx" */),
  "component---src-pages-expertise-tsx": () => import("./../../../src/pages/expertise.tsx" /* webpackChunkName: "component---src-pages-expertise-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legalnotice-tsx": () => import("./../../../src/pages/legalnotice.tsx" /* webpackChunkName: "component---src-pages-legalnotice-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */)
}

