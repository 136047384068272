import React, { useContext } from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import anime from "animejs"
import { MenuContext } from "./Page"
import { useTranslation } from "react-i18next"

const FadeLink: React.FC<{
  href: string
  className?: string
  onClick?: () => void
}> = ({ href, children, className, onClick }) => {
  const { closeMenu } = useContext(MenuContext)
  const { i18n } = useTranslation()

  const translatedHref =
    (i18n.language !== "de" ? `/${i18n.language}` : "") +
    (href.startsWith("/") ? "" : "/") +
    href

  return (
    <TransitionLink
      onClick={() => {
        closeMenu?.()
        onClick?.()
      }}
      className={className}
      exit={{
        length: 0.3,
        trigger: ({ node }: { node: any }) =>
          anime({
            targets: [node],
            duration: 300,
            opacity: [1, 0],
            easing: "linear",
          }),
      }}
      entry={{
        length: 0.3,
        trigger: ({ node }: { node: any }) =>
          anime({
            targets: [node],
            duration: 300,
            opacity: [0, 1],
            easing: "linear",
          }),
      }}
      to={translatedHref}
    >
      {children}
    </TransitionLink>
  )
}

export default FadeLink
