import React from "react"
import styled from "styled-components"
import { ModalHeader } from "../Modal"
import { bp } from "../Styles"

interface TextAlertProps {
  onClose?: () => void
  title?: string
}

const TextAlert: React.FC<TextAlertProps> = ({
  onClose = () => {},
  children,
  title,
}) => {
  return (
    <TextAlertContainer>
      <ModalHeader
        className="header"
        {...{ title }}
        onClose={() => onClose()}
      />

      {children}
    </TextAlertContainer>
  )
}

const TextAlertContainer = styled.div`
  padding: 2em 2.6em 3em 8.25em;

  font-size: 1rem;
  width: 56em;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .header {
    align-items: flex-start;
    margin-bottom: 2.35em;

    h3 {
      font-size: 3em;
      padding-top: 0.65em;
    }
  }

  p {
    width: 36em;
    max-width: 100%;
    line-height: 1.8;
    font-size: 1.05em;
    margin-bottom: 2em;
  }

  ${bp("mobile")`
    padding: 3em;

    p {
      font-size: 1.25rem;
    }

    .header h3 {
      font-size: 2.5em;
      line-height: 1.5;
      padding-top: 0;
    }
  `}
`

export default TextAlert
