import React, { useState } from "react"
import styled, { css } from "styled-components"
import { ModalHeader } from "../Modal"
import { bp, colors, fonts } from "../Styles"
import ExpandArrowSVG from "../../images/icons/UI-Icons_Navi_Pfeil.svg"
import SaveArrowSVG from "../../images/icons/UI-Icons_Pfeil_Grau.svg"
import Checkbox from "../Checkbox"
import { Collapse } from "react-collapse"
import { useTranslation } from "gatsby-plugin-react-i18next"

export enum AnalyticsOption {
  GoogleAnalytics = `ganalytics`,
  GoogleAdWords = `gadwords`,
}

export type CookieOptions = { [key: string]: boolean }

interface SettingsRow {
  title: string
  description: string
  options: { [key: string]: string }
}

export const defaultCookieOptions = {
  [AnalyticsOption.GoogleAnalytics]: true,
  [AnalyticsOption.GoogleAdWords]: false,
}

interface CookieSettingsProps {
  onClose?: () => void
  onSave?: (options: CookieOptions) => void
  savedOptions?: CookieOptions
}

const CookieSettings: React.FC<CookieSettingsProps> = ({
  onClose,
  onSave,
  savedOptions,
}) => {
  const [expandedIdx, setExpandedIdx] = useState(0)
  const [enabledOptions, setEnabledOptions] = useState<CookieOptions>({})
  const { t } = useTranslation()

  const rows: SettingsRow[] = [
    {
      title: t("Marketing Cookies"),
      description: t(
        "Diese Cookies setzen wir ein, um zielgruppenorientierte Werbung zu erstellen und das Nutzungsverhalten auf dieser Website zu analysieren."
      ),
      options: {
        [AnalyticsOption.GoogleAnalytics]: t("Google Analytics"),
        // [AnalyticsOption.GoogleAdWords]: t("Google AdWords"),
      },
    },
    // {
    //   title: t("Funktionale Cookies"),
    //   description: t(
    //     "Wir setzten diese Cookies ein, um auf Basis von Statistiken zu analysieren, wie unsere Website genutzt wird. Zudem setzen wir diese Cookies ein, um unsere Website zu optimieren und bestimmte - nicht notwendige - Funktionalitäten bereit zu stellen."
    //   ),
    //   options: {
    //     [AnalyticsOption.GoogleAnalytics]: t("Google Analytics"),
    //     [AnalyticsOption.GoogleAdWords]: t("Google AdWords"),
    //   },
    // },
    // {
    //   title: t("Marketing Cookies"),
    //   description: t(
    //     "Wir setzten diese Cookies ein, um auf Basis von Statistiken zu analysieren, wie unsere Website genutzt wird. Zudem setzen wir diese Cookies ein, um unsere Website zu optimieren und bestimmte - nicht notwendige - Funktionalitäten bereit zu stellen."
    //   ),
    //   options: {
    //     [AnalyticsOption.GoogleAnalytics]: t("Google Analytics"),
    //     [AnalyticsOption.GoogleAdWords]: t("Google AdWords"),
    //   },
    // },
  ]
  return (
    <CookieSettingsContainer>
      <ModalHeader
        title={t("Cookie Einstellungen")}
        onClose={() => onClose?.()}
      />

      <div className="settings">
        {rows.map((row, idx) => (
          <CookieSettingsRow
            key={idx}
            rowData={row}
            savedOptions={savedOptions}
            expanded={expandedIdx === idx}
            onClick={() => setExpandedIdx(idx)}
            onChangeOption={(option, value) =>
              setEnabledOptions(options => ({ ...options, [option]: value }))
            }
          />
        ))}
      </div>

      <button className="save" onClick={() => onSave?.(enabledOptions)}>
        <span>{t("Auswahl speichern und zustimmen")}</span>
        <SaveArrowSVG />
      </button>
    </CookieSettingsContainer>
  )
}

const CookieSettingsContainer = styled.div`
  background: #fff;
  font-size: 1.2rem;
  width: 30em;
  max-width: 100%;
  padding: 2.5em 3.25em;
  position: relative;
  .close-button {
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
  }
  h3 {
    font-size: 1.5em;
  }

  .settings {
    margin-top: -0.6em;
  }

  .save {
    font-size: 0.9em;
    padding-top: 2em;
    font-family: ${fonts.bold};
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 2.4em;
    cursor: pointer;

    span {
      color: ${colors.lightText};
    }

    svg {
      width: 1.4em;
    }
  }
`

interface CookieSettingsRowProps {
  rowData: SettingsRow
  expanded?: boolean
  onClick?: () => void
  onChangeOption?: (key: string, checked: boolean) => void
  savedOptions?: CookieOptions
}

const CookieSettingsRow: React.FC<CookieSettingsRowProps> = ({
  rowData,
  expanded = false,
  onClick,
  onChangeOption,
  savedOptions = {},
}) => (
  <CookieSettingsRowContainer expanded={expanded}>
    <button className="title" onClick={() => onClick?.()}>
      <h5>{rowData.title}</h5>

      <div className="expand">
        <ExpandArrowSVG />
      </div>
    </button>

    <Collapse isOpened={expanded}>
      <div className="description">
        <p>{rowData.description}</p>

        {Object.keys(rowData.options).map((opt, idx) => (
          <Checkbox
            key={idx}
            defaultChecked={
              savedOptions[opt] ?? defaultCookieOptions[opt as AnalyticsOption]
            }
            onChange={checked => onChangeOption?.(opt, checked)}
          >
            <span>{rowData.options[opt]}</span>
          </Checkbox>
        ))}
      </div>
    </Collapse>
  </CookieSettingsRowContainer>
)

const CookieSettingsRowContainer = styled.div<{ expanded?: boolean }>`
  border-bottom: 1px solid ${colors.lightGrey};

  .title {
    cursor: pointer;
    font-family: ${fonts.medium};
    font-size: 0.95em;
    padding: 1.65em 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .expand {
      margin-right: 0.8em;
    }

    svg {
      width: 0.6em;
      transition: transform 0.15s linear;

      ${props =>
        props.expanded &&
        css`
          transform: rotateZ(90deg);
        `}
    }
  }

  .ReactCollapse--collapse {
    transition: height 200ms;
  }

  .description {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    p {
      font-size: 0.75em;
      color: ${colors.lightText};
      line-height: 1.5;
      margin-bottom: 2.4em;
    }

    label {
      margin-bottom: 1.4em;
      font-size: 0.8em;
      padding-left: 3.5em;
    }
  }

  ${bp("mobile")`
    font-size: 1.4rem;
  `}
`

export default CookieSettings
