import { createGlobalStyle, css } from "styled-components"

export const fonts = {
  light: `"AvenirLTPro-Book", sans-serif`,
  medium: `"AvenirLTPro-Medium", sans-serif`,
  bold: `"AvenirLTPro-Black", sans-serif`,
  headline: `"EngschriftDIND", sans-serif`,
}

export const layout = {
  width: "103rem",
  maxWidth: "calc(100% - 12rem)",
  maxWidthMobile: "calc(100% - 5rem)",
}

export const colors = {
  primary: "#00FFAF",
  primaryDarker: "#FAFFC3",
  secondary: "#FAFFC3",
  secondaryLighter: "#ff923f",
  background: "#505050",
  backgroundLighter: "#666666",
  backgroundDarker: "#333333",
  lightGrey: "#d9d9d9",
  lightText: "#b8babc",
  white: "#ffffff",
  black: "#000000",
  blue: "#2D4B9B",
  slidePrimary: "#00FFAF",
  slideSecondry: "#FAFFC3",
  slideBlue: "#2D4B9B",
}

export const breakpoints = {
  mobile: `screen and (max-width: 1024px)`,
  mobile_mdpi: `screen and (max-width: 500px)`,
  mobile_ldpi: `screen and (max-width: 380px)`,
  ldpi: `screen and (max-width: 1400px)`,
  mdpi: `screen and (max-width: 1600px)`,
  hdpi: `screen and (min-width: 2000px)`,
  portrait: `screen and (orientation: portrait)`,
  ipad: `screen and (min-width : 812px) 
  and (max-width : 1024px)`,
}

type Breakpoint = keyof typeof breakpoints

export const bp =
  (point: Breakpoint) =>
  (...args: any) =>
    css`
      @media ${breakpoints[point]} {
        ${(css as any)(...args)}
      }
    `

const Styles = createGlobalStyle`
  html, body, * {
    font-family: ${fonts.light};
    color: ${colors.background};
    line-height: 1.618;
  }

  a {
    text-decoration: none;
    color: unset;
  }

  html, body {
    font-size: 16px;

    ${bp("mdpi")`font-size: 14px;`}
    ${bp("ldpi")`font-size: 13px;`}
    ${bp("mobile")`font-size: 11px;`}
    ${bp("mobile_mdpi")`font-size: 10px;`}
    ${bp("mobile_ldpi")`font-size: 9px;`}

    scrollbar-width: none;
    -ms-overflow-style: none;

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  strong {
    font-weight: bold;
  }

  h1, h2, h3, h4, h5 {
    font-family: ${fonts.headline};
    line-height: 1.1;
    letter-spacing: 1px;
  }
  br {
    line-height: 1;
  }

  .fade-in-section {
    opacity: 0;
    visibility: hidden;
    transition: opacity 500ms ease-out, transform 400ms ease-out,
    visibility 500ms ease-out;
    will-change: opacity, transform, visibility;
    width: 100%;
    &.is-visible {
      opacity: 1;
      transform: none;
      visibility: visible;
    }
  }
}
`

export default Styles
