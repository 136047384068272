import React, { useEffect, useRef, useState } from "react"
import Reset from "./Reset"
import { Helmet } from "react-helmet"
import "../fonts/fonts.css"
import { GTAG_OPTIN_KEY } from "gatsby-plugin-google-gtag-optin"
import MenuPart from "../components/Menu"

import SwiperCore, {
  Pagination,
  EffectCreative,
  Mousewheel,
  A11y,
} from "swiper"

import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/a11y"
import "swiper/css/mousewheel"
import Styles from "./Styles"
import useLocalStorage from "../util/use-local-storage"
import Modal from "./Modal"
import CookieNotice from "./modals/CookieNotice"
import CookieSettings, {
  AnalyticsOption,
  CookieOptions,
  defaultCookieOptions,
} from "./modals/CookieSettings"
import { isBrowser } from "../util/ssr"
import styled from "styled-components"

// install Swiper modules
SwiperCore.use([Pagination, EffectCreative, Mousewheel, A11y])

export const MenuContext = React.createContext({
  registerMenuClose: (_: () => void) => {},
  closeMenu: () => {},
})

interface CookieSettings {
  time: number
  saved: boolean
  options: CookieOptions
}

const initialSettings: CookieSettings = {
  time: -1,
  saved: false,
  options: defaultCookieOptions,
}

const Page: React.FC = ({ children }) => {
  const [cookieSettings, setCookieSettings] = useLocalStorage<CookieSettings>(
    "cookie-settings",
    initialSettings
  )

  const [showNotice, setShowNotice] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const [menuClose, setMenuClose] = useState<() => void>(() => {})

  useEffect(() => {
    if (!cookieSettings.saved) setShowNotice(true)
  }, [])

  const onCloseNotice = () => {
    setShowNotice(false)

    // Save default options when the user simply
    // closes the modal
    setCookieSettings({
      time: +new Date(),
      saved: true,
      options: defaultCookieOptions,
    })
  }

  const onCloseSettings = () => {
    setShowSettings(false)

    // Save default options when the user simply
    // closes the modal
    setCookieSettings({
      time: +new Date(),
      saved: true,
      options: defaultCookieOptions,
    })
  }

  const onSaveSettings = (options: CookieOptions) => {
    setShowSettings(false)

    if (options[AnalyticsOption.GoogleAnalytics]) {
      localStorage.setItem(GTAG_OPTIN_KEY, true as any)
      if (typeof (window as any).loadGtag == "function")
        (window as any).loadGtag()
    }

    // Save the options we received from the user when
    // save is clicked
    setCookieSettings({
      time: +new Date(),
      saved: true,
      options,
    })
  }

  const onGotoSettings = () => {
    setShowNotice(false)
    setShowSettings(true)
  }

  const onAcceptAll = () => {
    setShowNotice(false)

    localStorage.setItem(GTAG_OPTIN_KEY, true as any)
    if (typeof (window as any).loadGtag == "function")
      (window as any).loadGtag()

    const all = Object.values(AnalyticsOption).reduce(
      (acc, it) => ({ ...acc, [it]: true }),
      {}
    )

    // Put cookies everywhere
    setCookieSettings({
      time: +new Date(),
      saved: true,
      options: all,
    })
  }

  return (
    <>
      <Reset />
      <Styles />

      <Helmet htmlAttributes={{ lang: "de" }}>
        <meta charSet="utf-8" />
        <meta name="description" content="Garage51" />
        <title>Garage51</title>
        <link rel="canonical" href="https://garage51.de" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no"
        />
      </Helmet>

      <MenuContext.Provider
        value={{
          registerMenuClose: fn => setMenuClose(fn),
          closeMenu: menuClose,
        }}
      >
        <MenuPart />

        {isBrowser && (
          <>
            <Modal isShown={showSettings} showBackdrop disableOutsideClick>
              <CookieSettings
                onClose={() => onCloseSettings()}
                onSave={opts => onSaveSettings(opts)}
              />
            </Modal>

            <Modal isShown={showNotice} showBackdrop disableOutsideClick>
              <CookieNotice
                onClose={() => onCloseNotice()}
                onGotoSettings={() => onGotoSettings()}
                onAcceptAll={() => onAcceptAll()}
              />
            </Modal>

            {/* <ShowNoticeDebug */}
            {/*   onClick={() => { */}
            {/*     setShowNotice(true) */}
            {/*   }} */}
            {/* > */}
            {/*   Show Cookie Notice */}
            {/* </ShowNoticeDebug> */}
          </>
        )}

        <div className="global-page-content">{children}</div>
      </MenuContext.Provider>
    </>
  )
}

const ShowNoticeDebug = styled.button`
  display: block;
  position: fixed;
  z-index: 999;
  bottom: 10px;
  right: 10px;
`

export default Page
