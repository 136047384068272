module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Garage51","short_name":"Garage51","start_url":"/","background_color":"#f9f9f9","theme_color":"#00FFAF","display":"standalone","icon":"src/images/icons/G51_Logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1b6f9b5915625f5c2e762ec915d52fa3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"mobile":"screen and (max-width: 1024px)","mobile_mdpi":"screen and (max-width: 500px)","mobile_ldpi":"screen and (max-width: 380px)","ldpi":"screen and (max-width: 1400px)","mdpi":"screen and (max-width: 1600px)","hdpi":"screen and (min-width: 2000px)","widthMax350":"screen and (max-width: 350px)","widthMax420":"screen and (max-width: 420px)","widthMax460":"screen and (max-width: 460px)","widthMax710":"screen and (max-width: 710px)"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/home/happens/garage/garage-51-microsite/src/components/Page.tsx"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["de","en"],"redirect":true,"defaultLanguage":"de","siteUrl":"https://industry.garage51.de/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":":"}},
    }]
