import styled, { css } from "styled-components"
import { colors, fonts } from "./Styles"
import FadeLink from "./FadeLink"
import React from "react"

type Variant = "primary" | "secondary" | "plain" | "white"

// NOTE(hilmar): font, border, background
const colorForVariant = {
  primary: [colors.white, colors.background, colors.primary],
  secondary: [colors.secondary, colors.secondary, colors.white],
  plain: [colors.background, "transparent", "transparent"],
  white: [colors.background, colors.background, colors.white],
}

const getColor = (index: number) => (props: { variant?: Variant }) =>
  colorForVariant[props.variant || "primary"][index]

const fontColor = getColor(0)
const borderColor = getColor(1)
const backgroundColor = getColor(2)

const Button: React.FC<{
  href?: string
  onClick?: () => void
  className?: string
  variant?: Variant
}> = ({ onClick, href, className, children, variant }) => {
  return (
    <>
      {href && (
        <LinkInner href={href} className={className} variant={variant}>
          {children}
        </LinkInner>
      )}

      {!href && (
        <ButtonInner
          onClick={() => onClick?.()}
          className={className}
          variant={variant}
        >
          {children}
        </ButtonInner>
      )}
    </>
  )
}

const LinkInner = styled(FadeLink)<{ variant?: Variant }>`
  position: relative;
  height: 5em;
  color: ${fontColor};
  font-family: ${fonts.bold};
  font-size: 1.1rem;
  padding: 1em 3.22em 0.8em 3.22em;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate3d(0, 0, 0);
  cursor: pointer;

  ${props =>
    props.variant !== "plain" &&
    css`
      ::before,
      ::after {
        left: 0;
        content: " ";
        position: absolute;
        border-radius: 10px;
        pointer-events: none;
      }

      ::before {
        top: 0;
        width: 100%;
        height: calc(5em - 0.68em);
        margin-top: 0.795em;
        z-index: -1;
        background: ${backgroundColor};

        ${props =>
          props.variant === "white" &&
          css`
            box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.06);
          `}
      }

      ::after {
        bottom: 0;
        border: 0.114em solid ${borderColor};
        width: calc(100% - 0.795em);
        margin: 0 0.398em 0.398em 0.398em;
        height: calc(5em - 0.568em);
      }
    `}

  svg {
    width: 1.4em;
    margin-left: 0.818em;
  }
`

const ButtonInner = styled.button<{ variant?: Variant }>`
  position: relative;
  height: 5em;
  color: ${fontColor};
  font-family: ${fonts.bold};
  font-size: 1.1rem;
  padding: 1em 3.22em 0.8em 3.22em;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate3d(0, 0, 0);
  cursor: pointer;
  background: ${backgroundColor};
  ${props =>
    props.variant === "white" &&
    css`
      box-shadow: 0px 0px 20px 3px rgba(0, 0, 0, 0.06);
    `}
  svg {
    width: 1.4em;
    margin-left: 0.818em;
  }
`

export default Button
